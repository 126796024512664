import { useParams } from "react-router-dom";
import useFetch from "./useFetch";
import { useHistory } from "react-router-dom";

const BlogDetails = () => {
    const {id} = useParams();
    const {data:blog ,error,isPending}= useFetch('http://localhost:8000/blogs/' +id);
    const history = useHistory();
    
    // Asking JSON server to delete with this ID
    const handleClick = () => {
        fetch('http://localhost:8000/blogs/'+ blog.id, {
            method: 'DELETE'
        }).then(() => {
          history.push('/')
        })
    }
    
    
    return (
        <div className="blog-details">
            {isPending && <div>Loading...</div>}
            {error && <div> {error}</div>}
            {blog && (
                <article>
                    <h2>{blog.title}</h2>
                    <p>Written by {blog.author}</p>
                    <p>Posted on {blog.formattedDate}</p>
                    <div>{blog.body}</div>
                    <button onClick= {handleClick}>delete</button>
                </article>
            )}

        </div>
     );
}
 
export default BlogDetails;

